/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */
import React from 'react'
import { graphql } from 'gatsby'

import { Layout } from '@components/Layout'
import { SEO } from '@components/SEO'
import { NewsletterSection } from '@components/NewsletterSection'
import { Flex, Box, Br } from '@components/Grid'
import { Hero } from '@components/Hero'
import { Text } from '@components/Text'
import { H2, H4, Topline } from '@components/Heading'
import { List, Item } from '@components/List'
import { Button } from '@components/Button'
import { Blob } from '@components/Blob'

const randomBlobs = [
  '30% 70% 55% 45% / 38% 30% 70% 62%',
  '36% 64% 55% 45% / 61% 58% 42% 39%',
  '33% 67% 61% 39% / 55% 35% 65% 45%',
  '40% 60% 45% 55% / 42% 35% 65% 58%',
  '47% 53% 57% 43% / 33% 46% 54% 67%',
  '44% 56% 49% 51% / 52% 32% 68% 48%',
]

const LeadershipRetreatPage = ({ data: { content }, location }) => {
  return (
    <Layout>
      <SEO
        metadata={{
          title: content.seo.seoTitle,
          description: content.seo.seoDescription,
          pathname: location.pathname,
        }}
      />
      <Hero maxWidth="1000px">
        <Box
          width="100%"
          display="grid"
          gridTemplateColumns={['5fr 2fr']}
          gridTemplateAreas={["'heading blob'"]}
        >
          <Box gridArea="heading">
            <Hero.Topline>{content.firstSectionTopline}</Hero.Topline>
            <Hero.Heading content={content._rawFirstSectionHeading} />
          </Box>
          <Box gridArea="blob">
            <Blob
              position="absolute"
              bottom={4}
              width="200%"
              pb="200%"
              borderRadius="32% 68% 56% 44% / 68% 44% 56% 32%"
              css={{
                background:
                  'linear-gradient( 210deg, hsla(216,50%,89%, 0.7), hsla(218,37%,80%, 1.0) )',
              }}
            />
            <Blob
              fluid={content.firstSectionImage.asset.fluid}
              position="absolute"
              bottom={2}
              width="100%"
              pb="100%"
              borderRadius="41% 59% 64% 36% / 63% 51% 49% 37%"
            />
          </Box>
        </Box>
      </Hero>
      <Box
        width="100%"
        maxWidth="900px"
        mx="auto"
        mt={-5}
        px={[5, 6, 7]}
        pb={[7, 8, 9]}
        display="grid"
        gridTemplateColumns={['100%', null, '1fr 1fr']}
        gridTemplateAreas={[
          "'leftColumn' 'rightColumn'",
          null,
          "'leftColumn rightColumn'",
        ]}
      >
        <Box gridArea="leftColumn" pr={[0, 0, 5]}>
          <Text mb={[6]}>{content.firstSectionTextLeft}</Text>
          <H4
            mb={[6, 6, 0]}
            color="red.dark"
            content={content._rawFirstSectionClaim}
          />
        </Box>
        <Box gridArea="rightColumn" css={{ alignSelf: 'end' }} pl={[0, 0, 5]}>
          <Text>{content.firstSectionTextRight}</Text>
        </Box>
      </Box>
      <Box
        as="section"
        flexDirection="column"
        alignItems="center"
        bg="sectionBackground.light"
        px={[5, 6, 7]}
        py={[7, 8, 9]}
      >
        <Topline textAlign="center">{content.secondSectionTopline}</Topline>
        <H2
          content={content._rawSecondSectionHeading}
          textAlign="center"
          mb={[6, 7]}
        />
        <Box
          display="grid"
          maxWidth="800px"
          mx="auto"
          gridTemplateColumns={[
            '100%',
            null,
            'repeat(auto-fit, minmax(300px, 1fr))',
          ]}
          gridGap={7}
          pl={[0, 6]}
        >
          {content.secondSectionList.map((item, index) => (
            <Flex key={item} flexDirection="row">
              <Box>
                <Blob
                  bg="blue.400"
                  width="32px"
                  pb="32px"
                  mr={4}
                  borderRadius={randomBlobs[index % randomBlobs.length]}
                  css={{
                    background:
                      'linear-gradient( 210deg, hsl(216, 50%, 89%), hsl(218, 37%, 80%))',
                  }}
                />
              </Box>
              <Text color="blue.800">{item}</Text>
            </Flex>
          ))}
        </Box>
      </Box>

      <Flex
        as="section"
        flexDirection="column"
        alignItems="center"
        bg="white"
        px={[5, 6, 7]}
        py={[7, 8, 9]}
      >
        <Topline textAlign="center" mb={6}>
          {content.thirdSectionTopline1} <Br />
          {content.thirdSectionTopline2}
        </Topline>
        <H2
          content={content._rawThirdSectionHeading}
          mb={[6, 3]}
          textAlign="center"
        />
        <Box
          width="100%"
          maxWidth="1100px"
          display="grid"
          my={[4]}
          gridTemplateColumns={['100%', null, '1fr 1fr', '3fr 2fr']}
          gridTemplateAreas={["'blobs' 'content'", null, "'blobs content'"]}
        >
          <Box display="grid" gridArea="blobs" pr={[0, 0, 0, 8]}>
            <Flex
              justifySelf={['center', null, 'right']}
              alignSelf="center"
              mb={[5, null, 0]}
              mr={[0, null, 5]}
              width={['50%', '40%', '70%']}
              pb={['50%', '40%', '70%']}
              height={['0']}
            >
              <Blob
                fluid={content.thirdSectionIncludedImage.asset.fluid}
                display="block"
                width="100%"
                pb="100%"
                borderRadius="42% 58% 44% 56% / 66% 45% 55% 34%"
              />
              <Box
                position="absolute"
                bg="#00f"
                width="60%"
                height="60%"
                top="70%"
                left="0"
                borderRadius="62% 38% 58% 42% / 38% 40% 60% 62%"
                css={{
                  transform: 'translate3d(-50%, -50%, 0)',
                  background:
                    'linear-gradient( 210deg, hsla(216,50%,89%, 0.7), hsla(218,37%,80%, 1.0) )',
                }}
              />
            </Flex>
          </Box>
          <Box display="grid" gridArea="content">
            <Flex
              display="block"
              pl={[0, null, 5]}
              px={[0, 5, 0]}
              pt={[5]}
              justifySelf={['center', null, 'start']}
              alignSelf="center"
            >
              <List>
                {content.thirdSectionIncludedList.map(item => (
                  <Item key={item}>{item}</Item>
                ))}
              </List>
            </Flex>
          </Box>
        </Box>
        <Button mt={6} as="a" href="mailto:info@science-and-leadership.com">
          {content.thirdSectionCTA.label}
          {content.thirdSectionCTA.showArrow && <Button.Arrow />}
        </Button>
      </Flex>
      <Flex
        as="section"
        flexDirection="column"
        alignItems="center"
        bg="sectionBackground.light"
        px={[5, 6, 7]}
        py={[9]}
      >
        <Flex
          flexDirection={['column', null, 'row']}
          width="100%"
          maxWidth="800px"
          mx="auto"
          pr={[5, 6, 7]}
        >
          <Flex
            order={[2, 2, 1]}
            flexDirection="column"
            width={['100%', null, '60%']}
            pr={[0, 0, 5]}
            mt={[7, null, 0]}
          >
            <H4
              content={content._rawFourthSectionHeading}
              mb={[6, 6]}
              color="blue.700"
            />

            <Text content={content._rawFourthSectionText} mb={5} />
          </Flex>
          <Flex
            order={[1, 1, 2]}
            maxWidth={['300px']}
            mx={['auto']}
            width={['100%', null, '40%']}
            px={[5]}
          >
            <Box width="100%">
              <Blob
                position="absolute"
                top={[6, null, 4]}
                left={[8, null, 5]}
                width={['90%', null, '110%']}
                pb={['90%', null, '110%']}
                borderRadius="36% 64% 55% 45% / 61% 58% 42% 39%"
                css={{
                  background:
                    'linear-gradient( 210deg, hsla(216,50%,89%, 0.7), hsla(218,37%,80%, 1.0) )',
                }}
              />
              <Blob
                fluid={content.fourthSectionImage.asset.fluid}
                width="100%"
                pb="100%"
                borderRadius="47% 53% 57% 43% / 33% 46% 54% 67%"
              />
            </Box>
          </Flex>
        </Flex>
      </Flex>
      <NewsletterSection />
    </Layout>
  )
}

export const query = graphql`
  query LeadershipRetreatPage {
    content: sanityLeadershipRetreatPageSingleton {
      seo {
        seoDescription
        seoTitle
      }
      firstSectionTopline
      _rawFirstSectionHeading
      _rawFirstSectionClaim
      firstSectionTextRight
      firstSectionTextLeft
      firstSectionImage {
        asset {
          fluid(maxWidth: 520) {
            ...GatsbySanityImageFluid
          }
        }
      }
      secondSectionTopline
      _rawSecondSectionHeading
      secondSectionList
      thirdSectionTopline1
      thirdSectionTopline2
      _rawThirdSectionHeading
      thirdSectionIncludedList
      thirdSectionIncludedImage {
        asset {
          fluid(maxWidth: 800) {
            ...GatsbySanityImageFluid
          }
        }
      }
      thirdSectionCTA {
        label
        showArrow
      }
      _rawFourthSectionHeading
      _rawFourthSectionText(resolveReferences: { maxDepth: 10 })
      fourthSectionImage {
        asset {
          fluid(maxWidth: 500) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`

export default LeadershipRetreatPage
